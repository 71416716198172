<template>
  <b-modal
    id="modal-xl-cpn-add"
    ok-title="Save"
    centered
    size="xl"
    no-close-on-backdrop
    title="Add Component For Page"
    @ok="validationForm"
  >
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="8">
            <b-row>
              <b-col md="12">
                <b-form-group label="Content:" label-cols-md="3">
                  <validation-provider
                    #default="{ errors }"
                    name="Content"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="model.content"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Content"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="*Position:" label-cols-md="3">
                  <validation-provider
                    #default="{ errors }"
                    name="Position"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.position"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Position"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="*Component:" label-cols-md="3">
                  <validation-provider
                    #default="{ errors }"
                    name="Is active"
                    rules="required"
                  >
                    <v-select
                      v-model="model.component"
                      label="name"
                      :reduce="x => x.id"
                      :options="componentOptions"
                      :state="errors.length > 0 ? false : null"
                      @input="changCpn(model.component)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="4">
            <b-form-group label="*Avatar:" label-cols-md="3">
              <div class="d-flex align-items-center justify-content-center">
                <b-aspect aspect="1:1">
                  <b-img-lazy :src="model.avatar" fluid thumbnail rounded />
                </b-aspect>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  // BCardHeader,
  // BCardBody,
  // BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BButton,
  BForm,
  BFormTextarea,
  // BFormFile,
  BImgLazy,
  BAspect,
  BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import Config from '@/@config/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BCard,
    // BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // BCardHeader,
    // BCardBody,
    BForm,
    BFormTextarea,
    // BFormFile,
    BImgLazy,
    BAspect,
    BModal,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  props: {
    list: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      required,
      model: {
        avatar:
          'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/banner-6.8e3bf2b9.jpg',
        name: null,
        position: null,
        component: null,
        is_active: 1,
        slug: null,
        // order: null,
        // link: null,
        meta_description: null,
        meta_keyword: null,
        content: null,
      },
      activeOptions: Config.activeOptions,
      componentOptions: null,
      newList: [],
    }
  },
  watch: {
    list(val) {
      if (val) {
        this.newList = val
      }
    },
  },
  created() {
    this.newList = this.list
    this.loadComponentList()
  },
  methods: {
    changCpn(link) {
      this.model.avatar = link.avatar
    },
    generateSlug(name) {
      this.model.slug = this.convertToSlug(name)
    },
    async loadComponentList() {
      const templateid = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/components?template_id=${templateid}&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            const items1 = []
            res.data.data.items.map(i => {
              const item = {
                ...i,
                component_id: i.id,
              }
              items1.push(item)
              return i
            })
            this.componentOptions = items1.filter(
              this.comparer(this.newList, 'component_id'),
            )
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      const params = {
        // template_id: JSON.parse(localStorage.getItem('siteID')).id,
        // template_id: JSON.parse(localStorage.getItem('templateID')).id,
        // name: this.model.name,
        platform_page_id: this.$route.params.id,
        component_id: this.model.component,
        position: Number(this.model.position),
        // is_active: Number(this.model.is_active),
        // slug: this.model.slug,
        // link: this.model.link,
        // order: this.model.order,
        // meta_description: this.model.meta_description,
        // meta_keyword: this.model.meta_keyword,
        content: this.model.content,
      }
      if (this.model.avatar !== null) {
        params.avatar = this.model.avatar
      }
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_URL}/platform_page_component`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create Component for Page success',
                  },
                })
                this.$emit('modal-close', true)
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Login fail',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
