import { render, staticRenderFns } from "./PPageComponentAdd.vue?vue&type=template&id=e5496c7c&scoped=true&"
import script from "./PPageComponentAdd.vue?vue&type=script&lang=js&"
export * from "./PPageComponentAdd.vue?vue&type=script&lang=js&"
import style0 from "./PPageComponentAdd.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./PPageComponentAdd.vue?vue&type=style&index=1&id=e5496c7c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5496c7c",
  null
  
)

export default component.exports